// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { ReactNode, useContext } from "react";
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useParams,
} from "react-router-dom";

import App from "./components/App";
import Landing from "./components/Landing";

import Classroom from "./components/Classroom";
import CreateOrJoin from "./components/CreateOrJoin";
import Home from "./components/Home";
import Login from "./components/Login";
import routes from "./constants/routes.json";
import getUIStateContext from "./context/getUIStateContext";
import MeetingStatusProvider from "./providers/MeetingStatusProvider";

interface ParamTypes {
  roomId: string;
}

export default function Routes() {
  const [state] = useContext(getUIStateContext());

  const PrivateRoute = ({
    children,
    path: _path,
  }: {
    children: ReactNode;
    path: string;
  }) => {
    const { roomId } = useParams<ParamTypes>();

    return (
      <Route path={_path}>
        {state.classMode ? (
          children
        ) : (
          <Redirect to={`/room/${roomId}${routes.LOGIN}`} />
        )}
        v
      </Route>
    );
  };
  const Room = () => {
    const { path, url } = useRouteMatch();
    return (
      <>
        <PrivateRoute path={`${path}${routes.CLASSROOM}`}>
          <MeetingStatusProvider>
            <Classroom />
          </MeetingStatusProvider>
        </PrivateRoute>
        <PrivateRoute path={`${path}${routes.CREATE_OR_JOIN}`}>
          <CreateOrJoin />
        </PrivateRoute>
        <Route path={`${path}${routes.LOGIN}`}>
          <Login />
        </Route>
        {/* <Route>
            <Redirect to={routes.HOME} />
          </Route> */}
      </>
    );
  };
  return (
    <App>
      <Switch>
        <Route path={"/room/:roomId"}>
          {/* <div>test</div> */}
          <Room />
        </Route>
        <Route exact path={routes.HOME}>
          <Home />
        </Route>
        <Route>
          <Redirect to={routes.HOME} />
        </Route>
      </Switch>
    </App>
  );
}
