// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import classNames from "classnames/bind";
//import { ipcRenderer, remote } from 'electron';
import { Hub } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import ChimeSdkWrapper from "../chime/ChimeSdkWrapper";
import getChimeContext from "../context/getChimeContext";
import getMeetingStatusContext from "../context/getMeetingStatusContext";
import getUIStateContext from "../context/getUIStateContext";
import ClassMode from "../enums/ClassMode";
import MeetingStatus from "../enums/MeetingStatus";
import ViewMode from "../enums/ViewMode";
import Chat from "./Chat";
import styles from "./Classroom.module.css";
import ContentVideo from "./ContentVideo";
import Controls from "./Controls";
import DeviceSwitcher from "./DeviceSwitcher";
import Error from "./Error";
import LoadingSpinner from "./LoadingSpinner";
import LocalVideo from "./LocalVideo";
import RemoteVideoGroup from "./RemoteVideoGroup";
import Roster from "./Roster";
import ScreenShareHeader from "./ScreenShareHeader";
import Quiz from "./Quiz";
import MessageTopic from "../enums/MessageTopic";
import { DataMessage } from "amazon-chime-sdk-js";

const cx = classNames.bind(styles);

export default function Classroom() {
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const [state] = useContext(getUIStateContext());
  const { meetingStatus, errorMessage } = useContext(getMeetingStatusContext());
  const [isContentShareEnabled, setIsContentShareEnabled] = useState(false);
  const [viewMode, setViewMode] = useState(ViewMode.Room);
  const [isModeTransitioning, setIsModeTransitioning] = useState(false);

  useEffect(() => {
    const setQuizMode = (message: DataMessage) => {
      // message
      const attendeeId = chime?.configuration?.credentials?.attendeeId;
      if (!attendeeId) {
        return;
      }

      if (attendeeId !== message.senderAttendeeId) {
        console.log("message", message.json());
        Hub.dispatch("chime-view-mode", {
          event: "buttonClick",
          data: {
            viewMode: message.json(),
          },
          message: "",
        });
      }
    };

    const quizModeMessageUpdateCallback = {
      topic: MessageTopic.QuizMode,
      callback: setQuizMode,
    };

    chime?.subscribeToMessageUpdate(quizModeMessageUpdateCallback);

    return () => {
      chime?.unsubscribeFromMessageUpdate(quizModeMessageUpdateCallback);
    };
  }, [chime?.configuration?.credentials?.attendeeId]);

  const stopContentShare = async () => {
    setIsModeTransitioning(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    Hub.listen("chime-disable-screen-share-mode", () => {
      try {
        chime?.audioVideo?.stopContentShare();
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      } finally {
        setViewMode(ViewMode.Room);
        setIsModeTransitioning(false);
      }
    });
    Hub.dispatch("chime-disable-screen-share-mode", {
      event: "buttonClick",
      data: {},
      message: "",
    });
  };

  // Must pass a memoized callback to the ContentVideo component using useCallback().
  // ContentVideo will re-render only when one dependency "viewMode" changes.
  // See more comments in ContentVideo.
  const onContentShareEnabled = useCallback(
    async (enabled: boolean) => {
      if (enabled && viewMode === ViewMode.ScreenShare) {
        await stopContentShare();
      }
      setIsContentShareEnabled(enabled);
    },
    [viewMode]
  );

  useEffect(() => {
    Hub.listen("chime-view-mode", (capsule) => {
      setViewMode(capsule.payload.data.viewMode);
      if (state.classMode === ClassMode.Teacher) {
        chime?.sendMessage(
          MessageTopic.QuizMode,
          capsule.payload.data.viewMode
        );
      }
    });
  }, []);

  if (process.env.NODE_ENV === "production") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // Recommend using "onbeforeunload" over "addEventListener"
      window.onbeforeunload = async (event: BeforeUnloadEvent) => {
        // Prevent the window from closing immediately
        // eslint-disable-next-line
        event.returnValue = true;
        try {
          await chime?.leaveRoom(state.classMode === ClassMode.Teacher);
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        } finally {
          window.onbeforeunload = null;
          // remote.app.quit();
        }
      };
      return () => {
        window.onbeforeunload = null;
      };
    }, []);
  }

  const quizMode = viewMode === ViewMode.Quiz;

  return (
    <div
      className={cx("classroom", {
        roomMode: viewMode === ViewMode.Room,
        quizMode: viewMode === ViewMode.Quiz,
        screenShareMode: viewMode === ViewMode.ScreenShare,
        isModeTransitioning,
        isContentShareEnabled,
      })}
    >
      {meetingStatus === MeetingStatus.Loading && <LoadingSpinner />}
      {meetingStatus === MeetingStatus.Failed && (
        <Error errorMessage={errorMessage} />
      )}
      {meetingStatus === MeetingStatus.Succeeded && (
        <>
          <>
            <div className={cx("left")}>
              {viewMode === ViewMode.ScreenShare && (
                <ScreenShareHeader onClickStopButton={stopContentShare} />
              )}
              <div className={cx("contentVideoWrapper")}>
                <ContentVideo onContentShareEnabled={onContentShareEnabled} />
              </div>

              {quizMode && (
                <div className={cx("quizGroupWrapper")}>
                  <Quiz classMode={state.classMode || ClassMode.Student} />
                </div>
              )}
              <div className={cx("remoteVideoGroupWrapper")}>
                <RemoteVideoGroup
                  viewMode={viewMode}
                  isContentShareEnabled={isContentShareEnabled || quizMode}
                />
              </div>
              <div className={cx("localVideoWrapper")}>
                <div className={cx("controls")}>
                  <Controls
                    viewMode={viewMode}
                    onClickShareButton={async () => {
                      console.log("share screen");
                      setIsModeTransitioning(true);
                      await new Promise((resolve) => setTimeout(resolve, 200));
                      Hub.listen("chime-enable-screen-share-mode", async () => {
                        console.log("got event");
                        try {
                          // setIsPickerEnabled(false);
                          await chime?.audioVideo?.startContentShareFromScreenCapture();
                          setViewMode(ViewMode.ScreenShare);
                          setIsModeTransitioning(false);
                        } catch (error) {
                          // eslint-disable-next-line
                          console.error(error);
                          await stopContentShare();
                        }
                      });
                      Hub.dispatch("chime-enable-screen-share-mode", {
                        event: "buttonClick",
                        data: {},
                        message: "",
                      });
                    }}
                  />
                </div>
                <div className={cx("localVideo")}>
                  <LocalVideo />
                </div>
              </div>
            </div>
            <div className={cx("right")}>
              <div className={cx("titleWrapper")}>
                <div className={cx("title")}>{chime?.title}</div>
                <div className={cx("label")}>
                  <FormattedMessage id="Classroom.classroom" />
                </div>
              </div>
              <div className={cx("deviceSwitcher")}>
                <DeviceSwitcher />
              </div>
              <div className={cx("roster")}>
                <Roster />
              </div>
              <div className={cx("chat")}>
                <Chat />
              </div>
            </div>
          </>
        </>
      )}
    </div>
  );
}
