// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

enum MessageTopic {
  Chat = "chat-message",
  RaiseHand = "raise-hand",
  DismissHand = "dismiss-hand",
  Focus = "focus",
  QuizMode = "quiz-mode",
  QuizStart = "quiz-start",
  QuizQuestionEnd = "quiz-question-end",
  QuizNextQuestion = "quiz-next-question",
  QuizAnswer = "quiz-answer",
  QuizEnd = "quiz-end",
  QuizScore = "quiz-score",
}

export default MessageTopic;
