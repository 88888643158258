// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { Fragment } from "react";
import { render } from "react-dom";
import Amplify from "aws-amplify";

import { PlaymedyInfraStack } from "./cdk-export.json";
// import { AppContainer as ReactHotAppContainer } from 'react-hot-loader';

import "./app.global.css";
import Root from "./components/Root";

Amplify.configure({
  aws_appsync_region: PlaymedyInfraStack.Region, // Stack region
  aws_appsync_graphqlEndpoint: PlaymedyInfraStack.Endpoint, // AWS AppSync endpoint
  aws_appsync_authenticationType: PlaymedyInfraStack.AuthorizationType, //Primary AWS AppSync authentication type
  aws_appsync_apiKey: PlaymedyInfraStack.APIKey, // AppSync API Key
});

// const AppContainer = process.env.PLAIN_HMR ? Fragment : ReactHotAppContainer;
const AppContainer = Fragment;

document.addEventListener("DOMContentLoaded", () =>
  render(
    <AppContainer>
      <Root />
    </AppContainer>,
    document.getElementById("root")
  )
);
