// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

enum ViewMode {
  Room,
  ScreenShare,
  Quiz,
}

export default ViewMode;
