import React from "react";
import logo from "../logo.svg";
import styles from "./Landing.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

function Landing() {
  return (
    <div className={cx("container")}>
      <div className={cx("content")}>
        <img src={logo} className={cx("App-logo")} alt="logo" />
      </div>
    </div>
  );
}

export default Landing;
