import React, { useContext, useState, useEffect } from "react";
import ChimeSdkWrapper from "../chime/ChimeSdkWrapper";
import getChimeContext from "../context/getChimeContext";
import { DataMessage } from "amazon-chime-sdk-js";

import styles from "./Quiz.module.css";
import classNames from "classnames/bind";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import ClassMode from "../enums/ClassMode";
import MessageTopic from "../enums/MessageTopic";
import _ from "lodash";
import useQuizManager from "../hooks/useQuizManager";
import { time } from "console";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const cx = classNames.bind(styles);

type Props = {
  classMode: ClassMode;
};

const useStyles = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "stretch",
    flexDirection: "row",
    padding: "0px",
    margin: "0px",
  },

  content: {
    margin: "0px",
    padding: "0px",
    display: "flex",
    flexDirection: "row",
  },

  footer: {},
});

const QUIZ_TIME = 10;

export const Quiz = React.memo((props: Props) => {
  const quizManager = useQuizManager();

  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());

  const classes = useStyles();
  const { classMode } = props;

  const questions = quizManager.questions();
  const [current, setCurrent] = useState(0);
  const [selectedValue, setSelected] = useState(-1);

  const [counter, setCounter] = React.useState(QUIZ_TIME);

  const [quizStarted, setQuizStarted] = useState(false);

  const [answers, setAnswers] = useState<{ [attendeeId: string]: any }>({});
  const [scores, setScores] = useState<{ [attendeeId: string]: number }>({});

  const question = questions[Math.min(current, questions.length - 1)];

  const endQuiz = current > questions.length - 1;

  const timeUp = counter === 0;
  const correctAnswers = _(
    _.pickBy(answers, (value, key) => {
      return value?.answer === question.answer;
    })
  )
    .toPairs()
    .orderBy((item: any) => item[1].timestampMs, ["asc"])
    .value();

  const getScores = () => {
    const newScores = {
      ...scores,
    };

    if (correctAnswers.length) {
      const fastest = correctAnswers[0][0];
      const slowest = correctAnswers[correctAnswers.length - 1][0];

      for (let item of correctAnswers) {
        let id = item[0];
        let score = 2;
        if (id === fastest) {
          score = 5;
        } else if (id === slowest) {
          score = 1;
        }

        newScores[id] = (newScores[id] || 0) + score;
      }
    }
    return newScores;
  };

  useEffect(() => {
    const nextQuestion = (message: DataMessage) => {
      setCounter(current + 1 > questions.length - 1 ? 0 : QUIZ_TIME);
      setCurrent(current + 1);
      setSelected(-1);
      setAnswers({});
    };

    const quizStarted = (message: DataMessage) => {
      setQuizStarted(true);
      setCurrent(0);
      setCounter(QUIZ_TIME);
      setSelected(-1);
      setAnswers({});
    };

    const quizAnswered = (message: DataMessage) => {
      const newAnswers = {
        ...answers,
        [message.senderAttendeeId]: {
          timestampMs: message.timestampMs,
          answer: message.json(),
        },
      };
      setAnswers(newAnswers);
      console.log(newAnswers);
    };

    const quizScore = (message: DataMessage) => {
      const newscore = message.json();
      console.log("new Score", newscore);

      setScores(newscore);
    };

    const nextQuestionMessageUpdateCallback = {
      topic: MessageTopic.QuizNextQuestion,
      callback: nextQuestion,
    };

    const quizStartedMessageUpdateCallback = {
      topic: MessageTopic.QuizStart,
      callback: quizStarted,
    };
    const quizAnsweeMessageUpdateCallback = {
      topic: MessageTopic.QuizAnswer,
      callback: quizAnswered,
    };

    const quizScoreMessageUpdateCallback = {
      topic: MessageTopic.QuizScore,
      callback: quizScore,
    };

    chime?.subscribeToMessageUpdate(nextQuestionMessageUpdateCallback);
    chime?.subscribeToMessageUpdate(quizStartedMessageUpdateCallback);
    chime?.subscribeToMessageUpdate(quizAnsweeMessageUpdateCallback);
    chime?.subscribeToMessageUpdate(quizScoreMessageUpdateCallback);
    return () => {
      chime?.unsubscribeFromMessageUpdate(nextQuestionMessageUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(quizStartedMessageUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(quizAnsweeMessageUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(quizScoreMessageUpdateCallback);
    };
  }, [current, answers, correctAnswers]);

  useEffect(() => {
    if (quizStarted) {
      if (counter !== 0) {
        const timer =
          counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => (timer ? clearInterval(timer) : undefined);
      } else if (counter === 0 && !endQuiz) {
        if (classMode == ClassMode.Teacher) {
          const score = getScores();
          chime?.sendMessage(MessageTopic.QuizScore, JSON.stringify(score));
        }
      }
    }
  }, [counter, quizStarted, endQuiz]);

  const scoreBoard = _(scores).toPairs().orderBy([1], ["desc"]).value();

  let bgURL = undefined;

  if (!quizStarted) {
    bgURL =
      "https://bsmedia.business-standard.com/_media/bs/img/article/2019-11/03/full/1572796865-0693.jpg";
  } else if (question.imgUrl) {
    bgURL = question.imgUrl;
  }
  return (
    <div
      className={cx("contentQuiz", {
        timeUp: timeUp,
        endQuiz: endQuiz,
      })}
    >
      <div className={cx("quiz")}>
        <Card className={classes.root}>
          <div className={cx("QuizBoard")}>
            {(!quizStarted || question.imgUrl) && (
              <CardMedia className={classNames(cx("image"))} image={bgURL} />
            )}

            {quizStarted ? (
              <>
                {endQuiz && (
                  <div className={cx("end", "center")}>
                    <Typography gutterBottom variant="h2" component="h1">
                      End of quiz
                    </Typography>
                  </div>
                )}
                {!endQuiz && (
                  <div className={cx("question")}>
                    <div className={cx("box")}>
                      <Typography gutterBottom variant="h2" component="h2">
                        {question.question}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Question: {current + 1} of {questions.length} <br />
                        {!timeUp && (
                          <>
                            {counter} second{counter - 1 ? "s" : ""} left
                          </>
                        )}
                      </Typography>

                      {question.options.map((opt, index) => (
                        <div className={cx("option")} key={index}>
                          <Radio
                            checked={selectedValue === index}
                            value={opt}
                            disabled={timeUp}
                            onChange={() => {
                              setSelected(index);
                              chime?.sendMessage(
                                MessageTopic.QuizAnswer,
                                index + 1
                              );
                            }}
                          />
                          {opt}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className={cx("start")}>
                <div className={cx("box", "center")}>
                  {classMode == ClassMode.Teacher ? (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        chime?.sendMessage(MessageTopic.QuizStart, current);
                      }}
                    >
                      Start the Quiz
                    </Button>
                  ) : (
                    <Typography gutterBottom variant="h5" component="h2">
                      The quiz is about to start
                    </Typography>
                  )}
                </div>
              </div>
            )}
            {timeUp && !endQuiz && (
              <div className={cx("result")}>
                <div className={cx("box4")}>
                  <div className={cx("result-content")}>
                    <Typography gutterBottom variant="h2" component="h2">
                      {question.question}
                    </Typography>

                    {selectedValue > -1 ? (
                      <>
                        <Typography gutterBottom variant="body1" component="h4">
                          Your answer was{" "}
                          <span className={cx("answer")}>
                            {question.options[selectedValue]}
                          </span>
                        </Typography>
                        <Typography gutterBottom variant="body1" component="h4">
                          <span
                            className={cx({
                              correct: selectedValue + 1 === question.answer,
                              wrong: selectedValue + 1 !== question.answer,
                            })}
                          >
                            {selectedValue + 1 === question.answer
                              ? "Correct. Great Job! 👍🏽"
                              : "Your answer was wrong 😭"}
                          </span>
                        </Typography>
                      </>
                    ) : (
                      <Typography gutterBottom variant="body1" component="h4">
                        You did not try this one 👀
                      </Typography>
                    )}

                    {selectedValue + 1 !== question.answer && (
                      <Typography gutterBottom variant="body1" component="h4">
                        <span className={cx("correct")}>
                          "{question.options[question.answer - 1]}"
                        </span>{" "}
                        was the right answer
                      </Typography>
                    )}

                    <div className={cx("question-stats")}>
                      <Typography gutterBottom variant="h6" component="h6">
                        Question Info
                      </Typography>
                      <TableContainer>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          size="small"
                        >
                          <TableHead>
                            <TableCell>Category</TableCell>
                            <TableCell>Value</TableCell>
                          </TableHead>
                          <TableBody>
                            {correctAnswers.length > 0 && (
                              <>
                                <TableRow>
                                  <TableCell>
                                    Fastest{" "}
                                    <span className={cx("emoji")}>💨</span>{" "}
                                  </TableCell>
                                  <TableCell>
                                    {chime?.roster[correctAnswers[0][0]]?.name}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    Slowest{" "}
                                    <span className={cx("emoji")}>🐌</span>
                                  </TableCell>
                                  <TableCell>
                                    {
                                      chime?.roster[
                                        correctAnswers[
                                          correctAnswers.length - 1
                                        ][0]
                                      ]?.name
                                    }
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                            <TableRow>
                              <TableCell>Correct Answer(s)</TableCell>
                              <TableCell>{correctAnswers.length}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>#Questions</TableCell>
                              <TableCell>
                                <progress
                                  max={questions.length}
                                  value={current + 1}
                                >
                                  {" "}
                                  {current + 1}{" "}
                                </progress>
                                <br />
                                {current + 1} /{questions.length}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    {classMode === ClassMode.Teacher && (
                      <div className={cx("question-control")}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            chime?.sendMessage(
                              MessageTopic.QuizNextQuestion,
                              current
                            );
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {timeUp && (
              <div className={cx("scoreboard")}>
                <div className={cx("box3")}>
                  <Typography gutterBottom variant="h2" component="h3">
                    Scoreboard
                  </Typography>

                  <TableContainer>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableCell>Player</TableCell>
                        <TableCell>Score</TableCell>
                      </TableHead>
                      <TableBody>
                        {scoreBoard.map((item: any, idx: number) => (
                          <TableRow key={idx}>
                            <TableCell>
                              {idx === 0 && (
                                <span className={cx("emoji")}>🥇</span>
                              )}
                              {idx === 1 && (
                                <span className={cx("emoji")}>🥈</span>
                              )}
                              {idx === 2 && (
                                <span className={cx("emoji")}>🥉</span>
                              )}
                              {chime?.roster[item[0]]?.name}
                            </TableCell>
                            <TableCell>{item[1]}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
});

export default Quiz;
