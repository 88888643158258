import { useContext, useEffect, useState } from "react";
import { DataMessage } from "amazon-chime-sdk-js";
import ChimeSdkWrapper from "../chime/ChimeSdkWrapper";
import getChimeContext from "../context/getChimeContext";
import getUIStateContext from "../context/getUIStateContext";
import ClassMode from "../enums/ClassMode";
import MessageTopic from "../enums/MessageTopic";
import { API } from "aws-amplify";
const QUIZ_TIME = 10;

interface Question {
  answer: number;
  options: string[];
  question: string;
  imgUrl: string;
}

const query = `
query QuizQuestions {
  getQuestions {
    answer
    imgUrl
    options
    question
  }
}
`;

async function fetchNotes() {
  const data: any = await API.graphql({ query });
  console.log("data from GraphQL:", data);
  //TODO: fix type
  return data.data.getQuestions;
}

export default function useQuizManagerMode() {
  const [quiz, setQuiz] = useState<Question[]>([]);

  useEffect(() => {
    (async () => {
      const res = await fetchNotes();

      setQuiz(res);
    })();
  }, []);

  const questions = () => {
    return quiz;
  };

  return {
    questions,
  };
}
