// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

export default {
  "Login.title": `Tell me about you`,
  "Login.teacherTitle": `Hosts can`,
  "Login.teacherDescription1": `Create a party`,
  "Login.teacherDescription2": `Share audio, video, and screen`,
  "Login.teacherDescription3": `Send chat messages`,
  "Login.teacherDescription4": `Toggle focus:`,
  "Login.teacherToggleDescription1": `Focus mutes all guests`,
  "Login.teacherToggleDescription2": `Focus turns off guests chat`,
  "Login.teacherButton": `I'm a host`,

  "Login.studentTitle": `Guests can`,
  "Login.studentDescription1": `Join The Party`,
  "Login.studentDescription2": `Share video`,
  "Login.studentDescription3": `Raise hand`,
  "Login.studentDescription4": `When focus is off:`,
  "Login.studentToggleDescription1": `Unmute and share audio`,
  "Login.studentToggleDescription2": `Send chat messages`,
  "Login.studentButton": `I'm a guest`,

  "CreateOrJoin.teacherTitle": `Create or join a party`,
  "CreateOrJoin.studentTitle": `Join The Party`,
  "CreateOrJoin.titlePlaceholder": `Party`,
  "CreateOrJoin.namePlaceholder": `Your name`,
  "CreateOrJoin.continueButton": `Continue`,
  "CreateOrJoin.notTeacherLink": `Not a host? Click here.`,
  "CreateOrJoin.notStudentLink": `Not a guest? Click here.`,
  "CreateOrJoin.classRoomDoesNotExist": `Party does not exist`,
  "CreateOrJoin.serverError": `Server error`,

  "Classroom.classroom": `Party`,

  "RemoteVideoGroup.noVideo": `No one is sharing video`,

  "DeviceSwitcher.noAudioInputPlaceholder": `No microphone`,
  "DeviceSwitcher.noAudioOutputPlaceholder": `No speaker`,
  "DeviceSwitcher.noVideoInputPlaceholder": `No video device`,

  "Controls.turnOffFocusTooltip": `Turn off focus`,
  "Controls.turnOnFocusTooltip": `Turn on focus`,
  "Controls.unmuteTooltip": `Unmute`,
  "Controls.muteTooltip": `Mute`,
  "Controls.turnOnVideoTooltip": `Turn on video`,
  "Controls.turnOffVideoTooltip": `Turn off video`,
  "Controls.shareScreenTooltip": `Share screen`,
  "Controls.endClassroomTooltip": `End party`,
  "Controls.leaveClassroomTooltip": `Leave party`,
  "Controls.micMutedInScreenViewMode": `Mic muted`,
  "Controls.focusOnMessage": `Focus on`,
  "Controls.focusOffMessage": `Focus off`,

  "ScreenPicker.title": `Share your screen`,
  "ScreenPicker.applicationWindowTab": `Application window`,
  "ScreenPicker.yourEntireScreenTab": `Your entire screen`,
  "ScreenPicker.noScreen": `No screen`,
  "ScreenPicker.cancel": `Cancel`,
  "ScreenPicker.share": `Share`,

  "ScreenShareHeader.stopSharing": `Stop sharing`,
  "ScreenShareHeader.online": `{number} online`,

  "ChatInput.inputPlaceholder": `Type a chat message`,
  "ChatInput.raiseHandAriaLabel": `Raise hand`,

  "Roster.raiseHandAriaLabel": `Raised hand by {name}`,

  "RemoteVideo.raiseHandAriaLabel": `Raised hand`,

  "CPUUsage.getting": `Getting CPU usage...`,
};
